// App.js
import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import Demo from "./Demo";
import Home from "./Home";

const App = () => {
  return (
    <Router>
      <Switch>
        {/* Other routes go here */}
        {/* <Route path="/.well-known/apple-app-site-association">
          <OK />
        </Route> */}
        <Route path="/.well-known/apple-app-site-association">
          <Redirect to="/.well-known/apple-app-site-association" />
        </Route>
        <Route path="/.well-known/assetlinks.json">
          <Redirect to="/.well-known/assetlinks.json" />
        </Route>
        <Route path="/demo">
          <Demo />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
